import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import Select from 'components/commons/select';
import { constant, isEmpty, update } from 'lodash';
import { useDebounce } from 'utils/hooks';

import {
  Grid,
  IconButton,
  Paper,
  Tooltip,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { Delete as DeleteIcon, InfoOutlined as InfoIcon } from '@material-ui/icons';

import useStyles from './EvidenceListItem.styles';
import { ACTION_SPEC_METRIC_OPTIONS, ACTION_SPEC_ORDER_OPTIONS } from 'data/actionSpecs';

const EvidenceListItem = ({
  onClick,
  onChange,
  onDelete,
  initialValue,
  isSelected,
  onChangePosition,
  hasPrimary,
}) => {
  const classes = useStyles();
  const [evidence, setEvidence] = useState({});
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const debouncedEvidence = useDebounce(evidence, 500);

  useEffect(() => {
    if (!isEmpty(initialValue) && !gotInitialValue) {
      setEvidence(initialValue);
      setGotInitialValue(true);
    }
  }, [initialValue, gotInitialValue]);

  useEffect(() => {
    if (!isEmpty(debouncedEvidence)) {
      onChange(debouncedEvidence);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedEvidence]);

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      let pos = evidence.position;
      setEvidence({ ...update(evidence, name, constant(value)) });
      if (name === 'position') onChangePosition(evidence.name, pos, value);
    };

  return (
    <Paper
      className={classNames(classes.container, {
        [classes.selected]: isSelected,
      })}
      onClick={onClick}
      elevation={isSelected ? 4 : 0}
      square
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Select
            label="Metric"
            onChange={handleChange('metric')}
            value={evidence.metric}
            options={ACTION_SPEC_METRIC_OPTIONS}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            label="Order"
            onChange={handleChange('order')}
            value={evidence.order}
            options={ACTION_SPEC_ORDER_OPTIONS}
            margin="normal"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            id="average-label"
            label="Average"
            type="text"
            className={classes.textField}
            value={evidence.timeframeAverage}
            onChange={handleChange('timeframeAverage')}
            margin="normal"
            disabled={evidence.order === 'SECONDARY' && hasPrimary}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={(evidence.order === 'SECONDARY' && hasPrimary) ? "Can't be set, timeframe for Primary metric will be used" : "Average static timeframe, also used for graph timeframe. Default value of 24h will be used if not set"}
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <TextField
            id="baseline-label"
            label="Baseline"
            type="text"
            className={classes.textField}
            value={evidence.timeframeBaseline}
            onChange={handleChange('timeframeBaseline')}
            margin="normal"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title="Baseline static timeframe. Default value of 7d will be used if not set"
                    placement="top-start"
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

      </Grid>
      {isSelected && (
        <div className={classes.actionsContainer}>
          {!!onDelete && (
            <Tooltip title="Delete evidence">
              <IconButton onClick={onDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      )}
    </Paper>
  );
};

export default EvidenceListItem;
