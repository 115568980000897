import Keycloak from 'keycloak-js';
import { getHost } from 'utils/host';

const host = getHost();

const keycloakConfig = {
  url: `https://keycloak.${host}/`,
  realm: 'aristotle',
  clientId: 'aristotle',
};

export const tokenMinValiditySeconds = 36000;

const keycloak = new Keycloak(keycloakConfig);
export default keycloak;

export const getKeycloakInitOptions = () => {
  const keycloakInitOptions = {
    onLoad: 'check-sso',
    checkLoginIframe: false,
  };

  return keycloakInitOptions;
};

export const tokenLogger = (tokens) => {
  const tokensParsed = Object(tokens);
  if (tokensParsed.token && tokensParsed.refreshToken && tokensParsed.idToken) {
    localStorage.setItem('token', tokensParsed.token);
    localStorage.setItem('refreshToken', tokensParsed.refreshToken);
    localStorage.setItem('idToken', tokensParsed.idToken);
  }
};

export const clearCookies = () => {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
  });
};

export const clearSession = () => {
  clearCookies();
  sessionStorage.clear();
  localStorage.clear();
};
