import axios from 'axios';
import env from 'config/environment';
import keycloak from '../keycloak';

const unauthorizedInstance = axios.create({
  baseURL: env.HOST_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

unauthorizedInstance.interceptors.response.use((response) => response.data);

const authorizedInstance = axios.create({
  baseURL: env.HOST_URL,
});

authorizedInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  const userData = JSON.parse(localStorage.getItem('userData') || {});
  const tenantId = localStorage.getItem('tenantId') ?? '00000000-0000-0000-0000-000000000000';

  return {
    ...config,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      userId: userData.uid,
      tenantId: tenantId,
      'Content-Type': 'application/json',
      ...config.headers,
    },
    params: {
      tenant_id: localStorage.getItem('tenantId'),
      ...config.params,
    },
  };
});

authorizedInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (error && error.data) return Promise.reject(error.data.reason);
    if (error && error.meta) return Promise.reject(error.meta.error_detail);
    if (error) {
      if (error.message.includes('401') || error.message.includes('403')) {
        if (!keycloak.authenticated) {
          keycloak.login();
        }
      }
    }
    if (error && error.message) return Promise.reject(error.message);
    return Promise.reject("The server couldn't process your request, please try again later.");
  }
);

export { unauthorizedInstance as unauthorizedAxios, authorizedInstance as authorizedAxios };
