import { replace } from 'connected-react-router';

import * as authService from 'services/auth';

import { AUTOLOGIN_REQUESTED, LOGIN_FAILED, LOGIN_REQUESTED, LOGIN_SUCCESS } from 'reducers/auth';
import keycloak, { clearSession } from 'keycloak';

import { differenceInSeconds } from 'date-fns';

export const login = (credentials) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_REQUESTED });
    return authService
      .login(credentials)
      .then((userData) => {
        dispatch({ type: LOGIN_SUCCESS, payload: userData });
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.removeItem('notEnoughPermisions');
        dispatch(replace('/home'));
        return userData;
      })
      .catch((error) => {
        dispatch({ type: LOGIN_FAILED, payload: error });
        return Promise.reject(error);
      });
  };
};

export const autoLogin = () => {
  return (dispatch) => {
    dispatch({ type: AUTOLOGIN_REQUESTED });
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      const difference = differenceInSeconds(parsedUserData.exp * 1000, Date.now());
      if (difference > 0) {
        dispatch({ type: LOGIN_SUCCESS, payload: parsedUserData });
      }
      localStorage.removeItem('notEnoughPermisions');
    } else {
      const error = 'Your session has expired, please log in again.';
      dispatch({ type: LOGIN_FAILED, payload: error });
      return new Error(error);
    }
  };
};

export const logout = async () => {
  clearSession();
  keycloak.logout();
};
