import parseJwtToken from './shared/parseJwtToken';

export const login = () => {
  return new Promise(async (resolve) => {
    const token = localStorage.getItem('token');
    const tokenData = token ? parseJwtToken(token) : '';
    const userData = {
      isAuthed: true,
      authTime: Date.now(),
      uid: tokenData.sub,
      exp: tokenData.exp,
      token: token,
    };

    resolve(userData);
  });
};
