import { pink, teal } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: teal,
    secondary: pink,
  },
  overrides: {
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: teal[500],
        },
      },
    },
  },
});

export default theme;
