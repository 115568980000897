import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  AppBar as MUIAppBar,
  Avatar,
  Box,
  Toolbar,
  Typography
} from '@material-ui/core';

import Button from "components/commons/button";

import logo from "assets/imgs/aristotle.png";
import useStyles from "./AppBar.styles";

const AppBar = ({ activeRoute, onClickLogout }) => {
  const classes = useStyles();
  const tenantId = localStorage.getItem('tenantId');

  const navOptions = [
    { text: "Insights", to: "/insights" },
    { text: "Labels", to: "/labels" },
    { text: "Pledges", to: "/pledges" },
    { text: "Surveys", to: "/surveys" },
    { text: "Programs", to: "/programs" },
    { text: "Action Specs", to: "/action-specs" },
    { text: "Summary", to: "/summary" },
    { text: "Reports", to: "/reports" }
  ];

  const renderButton = ({ text, to }) => {
    const isActive = activeRoute.includes(to);
    return (
      <Link
        key={text}
        to={to}
        className={classNames(classes.whiteText, classes.navigationItem, isActive && classes.activeText)}
      >
        {text}
      </Link>
    );
  };

  return (
    <MUIAppBar
      classes={{
        colorDefault: classes.defaultColor
      }}
      color="default"
      position="fixed"
    >
      <Toolbar>
       
        <Link key={'aristotle'} to={'/home'} className={classes.logoText}>
        <Avatar alt="Aristotle logo" src={logo} className={classes.logo} />
            <div>
              <Typography className={classNames(classes.whiteText, classes.logoText)} color="inherit" variant="h6">
                ARISTOTLE
              </Typography>
            
          <span className={classNames(classes.whiteText)}>
            Tenant: { localStorage.getItem('tenantName') || 'Not selected'}
          </span>
          </div>
        </Link>
        <div className={classes.divider} />
        {tenantId && navOptions.map(renderButton)}
        <Box flexGrow={1} />
        <Button color="primary" onClick={() => onClickLogout()}>
          Log out
        </Button>
      </Toolbar>
    </MUIAppBar>
  );
};

export default AppBar;
