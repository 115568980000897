import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { render } from 'react-dom';

import 'typeface-roboto';
import './index.css';

import keycloak, { getKeycloakInitOptions, tokenLogger } from 'keycloak';
import store, { history } from 'config/store';

import { AppContainer } from 'containers/views';
import CssBaseline from '@material-ui/core/CssBaseline';
import { DialogTemplateContainer } from 'containers/templates';
import { LoadingComponent } from 'components/commons';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from 'config/theme';

const target = document.querySelector('#root');

render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={getKeycloakInitOptions()}
    autoRefreshToken={true}
    onTokens={tokenLogger}
    LoadingComponent={<LoadingComponent />}
  >
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DialogTemplateContainer />
          <AppContainer />
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  </ReactKeycloakProvider>,
  target
);
