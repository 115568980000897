import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import PropTypes from 'prop-types';

import { LoadingComponent } from 'components/commons';

const Login = ({ actions, isAuthed }) => {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    } else {
      actions.login();
    }
  }, [actions, isAuthed, keycloak]);

  return <LoadingComponent />;
};

Login.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.object.isRequired,
};

export default Login;
